<header>
  <mat-toolbar *ngIf="logado|async" >
      <button *ngIf="logado|async" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    <span routerLink="relatorio" class="col-lg-2 col-md-2 col-sm-3 col-xs-3"><img mat-button src="assets/images/logoBranco2.png" class="w-100" alt=""></span>

    <!-- <span >
      <div class="col-md-2">
        <mat-menu #appMenu="matMenu" >
          <div class="row justify-content-center" style="width: 100%; margin: 0;">
            <img  src="assets/images/robo.png" class="w-50" style="border-radius: 50%;" alt="">
          </div>
          <span mat-menu-item routerLink="conta">Conta</span>
          <span mat-menu-item (click)="deslogar()">Logoff</span>
          <span mat-menu-item class="text-center" >v.<strong>0.16</strong></button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu" [innerHTML]="(user|async)?.user"></button>
      </div>
    </span> -->
  </mat-toolbar>
  <!-- <mat-toolbar >
      <span class="logo col-md-2">
        <img mat-button src="assets/images/logoBranco.png" class="w-100" alt="">
      </span>
      <span class="example-fill-remaining-space"></span>
      <div class="col-md-8">
        <a class="nav-link-white" mat-button [routerLink]="['/${route-link}']">teste1</a>
        <a class="nav-link-white" mat-button [routerLink]="['/${route-link}']">teste2</a>
        <a class="nav-link-white" mat-button [routerLink]="['/${route-link}']">teste3</a>
        <a class="nav-link-white" mat-button [routerLink]="['/${route-link}']">teste4</a>
      </div>
      <div class="col-md-2">
        <mat-menu #appMenu="matMenu" >
          <button mat-menu-item>Settings</button>
          <button mat-menu-item>Help</button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          {{(user|async)?.user}}
        </button>
      </div>
    </mat-toolbar> -->
  <!-- <mat-expansion-panel>
      <mat-expansion-panel-header>
        This is the expansion title
      </mat-expansion-panel-header>

      <p>This is the primary content of the panel.</p>

      <mat-action-row>
        <button mat-button>Click me</button>
      </mat-action-row>
    </mat-expansion-panel> -->
</header>
<main >
  <mat-sidenav-container >
    <mat-sidenav #sidenav mode="over">
      <!-- teste de tree -->
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="material-symbols-outlined">
                content_paste
              </mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              Relatórios
            </mat-panel-description>
          
          </mat-expansion-panel-header>
          <mat-nav-list (click)="sidenav.toggle()">
            <a class="nav-link-white" mat-list-item [routerLink]="['relatorio']" >Chamadas</a>

            <a class="nav-link-white" mat-list-item [routerLink]="['relatorio/relatorio-callcenter']">Callcenter</a>

            <a class="nav-link-white" mat-list-item [routerLink]="['relatorio/relatorio-agentes']">Agentes</a>

            <a class="nav-link-white" mat-list-item [routerLink]="['relatorio/relatorio-gravacoes']">Gravações</a>
          </mat-nav-list>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- teste online Sub -->
      <mat-accordion *ngIf="(on|async)" >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
          <mat-panel-title>
              <mat-icon class="material-symbols-outlined">
                sensors
              </mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              Online
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-nav-list (click)="sidenav.toggle()">
            <a class="nav-link-white" mat-list-item [routerLink]="['online']" >Ramais</a>
          </mat-nav-list>
          <mat-nav-list (click)="sidenav.toggle()">
            <a class="nav-link-white" mat-list-item [routerLink]="['online/filas']">Callcenter</a>
          </mat-nav-list>
          <mat-nav-list (click)="sidenav.toggle()">
            <a class="nav-link-white" mat-list-item [routerLink]="['online/membros']">Agentes</a>
          </mat-nav-list>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- teste de tree -->
      <!-- <mat-nav-list (click)="sidenav.toggle()">
        <a class="nav-link-white" mat-list-item [routerLink]="['online']">Online</a>
      </mat-nav-list> -->
      <mat-nav-list (click)="sidenav.toggle()" *ngIf="(botAccess|async)">
        <mat-list-item>
          <mat-icon class="white" matListItemIcon>smart_toy</mat-icon>
          <a class="nav-link-white" matListItemTitle [href]="(botInfo|async)" target="_blank" >ChatBot</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list (click)="sidenav.toggle()" *ngIf="!(botAccess|async)">
        <mat-list-item>
          <mat-icon class="white" matListItemIcon>smart_toy</mat-icon>
          <a class="nav-link-white" mat-list-item [routerLink]="['chatbot']">ChatBot</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list (click)="sidenav.toggle()" *ngIf="(dir|async)">
        <mat-list-item>
          <mat-icon class="white" matListItemIcon>folders</mat-icon>
          <a class="nav-link-white" [routerLink]="['diretorios']">Diretórios</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon class="white" matListItemIcon>account_circle</mat-icon>
          <a class="nav-link-white" mat-list-item [routerLink]="['conta']">Conta</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon class="white" matListItemIcon>help</mat-icon>
          <a class="nav-link-white" mat-list-item [routerLink]="['ajuda']">Ajuda</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon class="white" matListItemIcon>logout</mat-icon>
          <a class="nav-link-white" mat-list-item (click)="deslogar()"> Logoff</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list class="footer-side">
        <span mat-list-item >v.<strong>0.42.1</strong></span>
      </mat-nav-list>

      <!-- <mat-nav-list>
        <a class="nav-link-white" mat-list-item [routerLink]="['']">Atendimentos</a>
      </mat-nav-list>

      <mat-nav-list>
        <a class="nav-link-white" mat-list-item [routerLink]="['']">Historico</a>
      </mat-nav-list>

      <mat-nav-list>
        <a class="nav-link-white" mat-list-item [routerLink]="['']">Logar</a>
      </mat-nav-list> -->
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<!-- <footer class="page-footer font-small blue" *ngIf="logado|async"> -->
  <!-- Copyright -->
  <!-- <div class="footer-copyright text-center py-3">
    © 2020 Copyright
    <a class="nav-link-white" href="https://mdbootstrap.com/"> - GNEW Tecnology </a>
  </div> -->
  <!-- Copyright -->
<!-- </footer> -->
